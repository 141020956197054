@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000300;
}
.nav-active {
  color: #ffffff91;
}
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-up {
  animation: slideUp 1s ease-out forwards;
}